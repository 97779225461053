import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import innerPeace from './peace.mov';
import katzbach from './katzbach.mov'
import React, { useEffect, useRef, useState } from 'react';
import audioBopBob from './bopForBob.mp3';
import audioConchaPop from './concha.wav';
import audioFallingSilent from './falling_silent.wav';
import audioGreenBlue from './green.wav';
import audioHangDrum from './drum.wav';
import audioHeidis from './heidi.wav'
import audioSocialDistance from './social.wav';
import audioGonePop from './gone.wav';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPlayer from 'react-player';
import './App.css';
import EyesModal from './components/EyesModal';
import EarsModal from './components/EarsModal';
import NoseModal from './components/NoseModal';
import Papa from 'papaparse';
import MediaQuery from 'react-responsive';


function App() {

  const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
      const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
      })

      const handleResize = () => {
        setDimensions(getDimensions())
      }

      if (myRef.current) {
        setDimensions(getDimensions())
      }

      const landing = document.getElementById('landing');

      window.addEventListener("resize", handleResize)
      landing.addEventListener("click", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])

    console.log(dimensions)

    return dimensions;
  };

  const MouseImg = useRef();

  const { width, height } = useContainerDimensions(MouseImg);

  const [showNose, setShowNose] = useState(false);
  const [showEars, setShowEars] = useState(false);
  const [showEyes, setShowEyes] = useState(false);
  const [showTail, setShowTail] = useState(false);

  const [showBob, setShowBob] = useState(false);
  const [showConcha, setShowConcha] = useState(false);
  const [showFallingSilent, setShowFallingSilent] = useState(false);
  const [showGreenBlue, setShowGreenBlue] = useState(false);
  const [showHangDrum, setShowHangDrum] = useState(false);
  const [showHeidi, setShowHeidi] = useState(false);
  const [showKatzbach, setShowKatzbach] = useState(false);
  const [showSocialDistance, setShowSocialDistance] = useState(false);
  const [showPeace, setShowPeace] = useState(false);
  const [showGone, setShowGone] = useState(false);
  const [fullscreen] = useState(true);

  /**
   * HANDLE AUDIO OF GHOSTS
  */

  const [audioBob] = useState(new Audio(audioBopBob))
  const [audioConcha] = useState(new Audio(audioConchaPop))
  const [audioFalling] = useState(new Audio(audioFallingSilent))
  const [audioGreen] = useState(new Audio(audioGreenBlue))
  const [audioDrum] = useState(new Audio(audioHangDrum))
  const [audioHeidi] = useState(new Audio(audioHeidis))
  const [audioSocial] = useState(new Audio(audioSocialDistance))
  const [audioGone] = useState(new Audio(audioGonePop))


  async function getData() {
    return Papa.parse(await fetchCsv());
  }

  async function fetchCsv() {
    const response = await fetch('./shows.csv');
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(result.value);
  }

  async function awaitgetData() {

    return await getData();
  }

  const csvData = awaitgetData();


  /**
   * HANDLE ANIMATION OF LANDING PAGE TO HOME PAGE
   */

  const handleAnimation = () => {
    const img = document.querySelector('.fadeOut');
    if (img) {
      img.classList.add('fadeOutAnimation');
    }

    setTimeout(() => {
      handleDisplay();
    }, 2500)

  }

  const handleDisplay = () => {
    const img = document.querySelector('.fadeOut');
    if (img) {
      img.classList.add('display');
    }
  }
  /**
   *  MOUSE NOSE CLICK FUNCTIONS AND CSS
  */

  function handleShowNose() {
    setShowNose(true);
  }

  function handleShowEyes() {
    setShowEyes(true);
  }

  function handleShowEars() {
    setShowEars(true);
  }

  function handleShowTail() {
    setShowTail(true);
  }


  /**
  *  GHOSTS
  */


  function handleShowBob() {
    setShowBob(true);

    audioBob.play();
  }

  function handleShowConcha() {
    setShowConcha(true);

    audioConcha.play();
  }

  function handleShowFallingSilent() {
    setShowFallingSilent(true);

    audioFalling.play();
  }

  function handleShowGreenBlue() {
    setShowGreenBlue(true);

    audioGreen.play();
  }

  function handleShowHangDrum() {
    setShowHangDrum(true);

    audioDrum.play();
  }

  function handleShowHeidi() {
    setShowHeidi(true);

    audioHeidi.play();
  }

  function handleShowKatzbach() {
    setShowKatzbach(true);
  }

  function handleShowSocialDistance() {
    setShowSocialDistance(true);

    audioSocial.play();
  }

  function handleShowPeace() {
    setShowPeace(true);
  }

  function handleShowGone() {
    setShowGone(true);

    audioGone.play();
  }



  return (
    <div className="App">

      {/* LANDING PAGE */}
      
      <div className='welcomePage fadeOut'>
        <div className="textContainer">
          <p className="introText">“Falling Silent” refers to an interview that happened to fall into my hands in the summer of 2019.<br />
            Its topic affected me so deeply that I wanted to dedicate it a piece of music.<br />
            Only eight months later, it was human civilization itself that fell silent: with the lockdowns imposed in the wake of the pandemic, life in the cities froze...
          </p>
          <p className="introAuthor">2024, Lars Arens, trombonist & composer</p>
        </div>
        <img src={require('./logo_intro.png')} alt="" />
        <button id='landing' type='button' className='welcomeButton' onClick={handleAnimation}>
          VIEW MORE
        </button>
      </div>

      {/* GHOSTS */}
      <a href='#' onClick={handleShowBob}>
        <Image id='bob' className='ghost-animation' src={require('./BopforBob.png')} />
      </a>
      <a href='#' onClick={handleShowConcha}>
        <Image id='concha' className='ghost-animation' src={require('./Concha.png')} />
      </a>
      <a href='#' onClick={handleShowFallingSilent}>
        <Image id='falling' className='ghost-animation' src={require('./FallingSilent.png')} />
      </a>
      <a href='#' onClick={handleShowGreenBlue}>
        <Image id='canada' className='ghost-animation' src={require('./canada.png')} />
      </a>
      <a href='#' onClick={handleShowHangDrum}>
        <Image id='drum' className='ghost-animation' src={require('./drum.png')} />
      </a>
      <a href='#' onClick={handleShowHeidi}>
        <Image id='heidi' className='ghost-animation' src={require('./heidi.png')} />
      </a>
      <a href='#' onClick={handleShowKatzbach}>
        <Image id='kat' className='ghost-animation' src={require('./Katzbach.png')} />
      </a>
      <a href='#' onClick={handleShowSocialDistance}>
        <Image id='social' className='ghost-animation' src={require('./mask.png')} />
      </a>
      <a href='#' onClick={handleShowPeace}>
        <Image id='peace' className='ghost-animation' src={require('./peace.png')} />
      </a>
      <a href='#' onClick={handleShowGone}>
        <Image id='gone' className='ghost-animation' src={require('./gone.png')} />
      </a>

      {/* MOUSE INTERACTION BUTTONS */}

      <div id="mouse">
        <div className="container">
          <img className='mouse' ref={MouseImg} src={require('./mouse.png')} alt="" />

            <div className="buttonsWrapper" style={{
                width: width > 0 ? width : '100%',
                height: height > 0 ? height : '100%'
            }}>
                <img alt='' className='mouse-nose-button' src={require('./mouse-nose.png')} onClick={handleShowNose}/>
                <img alt='' className='mouse-eyes-button' src={require('./mouse-eye.png')} onClick={handleShowEyes}/>
                <img alt='' className='mouse-ears-button' src={require('./mouse-ear.png')} onClick={handleShowEars}/>
                <img alt='' className='mouse-ears-button-2' src={require('./mouse-ear-2.png')}
                     onClick={handleShowEars}/>
                <img alt='' className='mouse-tail-button' src={require('./mouse-tail.png')} onClick={handleShowTail}/>

            </div>
        </div>
      </div>

        <div id="signature">
            <img src={require('./moborg signature.png')} alt="" />
      </div>

      {/** GHOST CLICK MODAL */}

      <Modal contentClassName='ghost-bob-content' dialogClassName='ghost-bob-modal' centered show={showBob} onHide={() => { setShowBob(false); audioBob.pause(); audioBob.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-concha-content' dialogClassName='ghost-concha-modal' centered show={showConcha} onHide={() => { setShowConcha(false); audioConcha.pause(); audioConcha.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-falling-content' dialogClassName='ghost-falling-modal' centered show={showFallingSilent} onHide={() => { setShowFallingSilent(false); audioFalling.pause(); audioFalling.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-canada-content' dialogClassName='ghost-canada-modal' centered show={showGreenBlue} onHide={() => { setShowGreenBlue(false); audioGreen.pause(); audioGreen.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-drum-content' dialogClassName='ghost-drum-modal' centered show={showHangDrum} onHide={() => { setShowHangDrum(false); audioDrum.pause(); audioDrum.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-heidi-content' dialogClassName='ghost-heidi-modal' centered show={showHeidi} onHide={() => { setShowHeidi(false); audioHeidi.pause(); audioHeidi.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-katzbach-content' dialogClassName='ghost-katzbach-modal' centered show={showKatzbach} onHide={() => setShowKatzbach(false)}>
        <MediaQuery minHeight={651} >
        <ReactPlayer playsinline id='video' url={katzbach} playing={true} style={{ borderStyle: 'solid', borderColor: 'white' }} width='362px' height='506px' />
        </MediaQuery>
        <MediaQuery maxHeight={650}>
          <ReactPlayer playsinline id='video' url={katzbach} playing={true} style={{ borderStyle: 'solid', borderColor: 'white' }} width={width} height={width / 0.71541501976} />
        </MediaQuery>
      </Modal>

      <Modal contentClassName='ghost-social-content' dialogClassName='ghost-social-modal' centered show={showSocialDistance} onHide={() => { setShowSocialDistance(false); audioSocial.pause(); audioSocial.currentTime = 0; }}>
      </Modal>

      <Modal contentClassName='ghost-peace-content' dialogClassName='ghost-peace-modal' centered show={showPeace} onHide={() => setShowPeace(false)}>
        <MediaQuery minHeight={651} >
        <ReactPlayer playsinline id='video' url={innerPeace} playing={true} style={{ borderStyle: 'solid', borderColor: 'white' }} width='362px' height='506px' />
        </MediaQuery>
        <MediaQuery maxHeight={650}>
          <ReactPlayer playsinline id='video' url={innerPeace} playing={true} style={{ borderStyle: 'solid', borderColor: 'white' }} width={width} height={width / 0.71541501976} />
        </MediaQuery>
      </Modal>

      <Modal contentClassName='ghost-gone-content' dialogClassName='ghost-gone-modal' centered show={showGone} onHide={() => { setShowGone(false); audioGone.pause(); audioGone.currentTime = 0; }}>
      </Modal>

      {/** TAIL CLICK MODAL */}
      {/** DONE*/}

      <Modal contentClassName='modal-tail-content' dialogClassName='modal-tail-dialog' centered show={showTail} onHide={() => setShowTail(false)}>
        <ModalHeader closeButton closeVariant='white'>
          <ModalTitle></ModalTitle>
        </ModalHeader>
          <ModalBody>
              <h1>Label</h1>
              <a href="https://unitrecords.com/artists/mborg-2" target="_blank"><h3>UnitRecords</h3></a>
              <br/>
              <h1>Contact:</h1>
              <a href="mailto:contact@moborg2.com"><h3>contact@moborg2.com</h3></a>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
      </Modal>

        {/** EARS CLICK MODAL */}
      {/** DONE*/}

      <Modal contentClassName='modal-ears-content' dialogClassName='modal-ears-dialog' centered show={showEars} onHide={() => setShowEars(false)}>
        <ModalHeader closeButton closeVariant='white'>
          <ModalTitle></ModalTitle>
        </ModalHeader>
        <ModalBody>
          <EarsModal />
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

      {/** EYES CLICK MODAL */}

      <Modal contentClassName='modal-eyes-content' dialogClassName='modal-eyes-dialog' centered show={showEyes} onHide={() => setShowEyes(false)}>
        <ModalHeader closeButton closeVariant='white'>
          <ModalTitle></ModalTitle>
        </ModalHeader>
        <ModalBody>
          <EyesModal data={csvData} />
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

      {/** NOSE CLICK MODAL*/}
      {/** DONE*/}

      {/* eslint-disable-next-line no-undef */}
      <Modal scrollable={true} backdropClassName='modalBackdrop' contentClassName='modal-nose-content' show={showNose} fullscreen={fullscreen} onHide={() => setShowNose(false)}>
        <Modal.Header closeButton closeVariant='white'>
          <ModalTitle></ModalTitle>
        </Modal.Header>
        <ModalBody>
          <NoseModal />
        </ModalBody>
      </Modal>

    </div>
  );
}

export default App;
