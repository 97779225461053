import { Row, Col, Button, Container } from 'react-bootstrap';

function EarsModal() {

    return(
        <div>
            <h1>Where to Listen to Falling Silent:</h1>
          <br />
          <h4>Digital Release on November 15th.</h4>
          {<Container>
            <Row>
              <Col><a href='https://music.apple.com/us/album/falling-silent/1771683621' target='_blank'><Button style={{
                  backgroundImage: "url('./img/apple_music.png')",
                  marginTop: '20px',
                  backgroundColor: '#000000',
                  border: '#000000',
                  backgroundSize: "cover",
                  width: "100px",
                  height: "100px"
              }}></Button></a></Col>
                <Col><a href='https://open.spotify.com/album/5a5d1i6vBHxuK1WHNgC05Z' target='_blank'><Button style={{
                    backgroundImage: "url('./img/spotify.png')",
                    marginTop: '20px',
                    backgroundColor: '#000000',
                    border: '#000000',
                    backgroundSize: "cover",
                    width: "100px",
                    height: "100px"
                }}></Button></a></Col>
            </Row>
            <Row>
              <Col><a href='https://music.apple.com/us/album/falling-silent/1771683621' target='_blank'><h2>Apple
                  Music</h2></a></Col>
                <Col><a href='https://open.spotify.com/album/5a5d1i6vBHxuK1WHNgC05Z' target='_blank'>
                    <h2>Spotify</h2></a></Col>
            </Row>
          </Container>}
        </div>
)
}

export default EarsModal